@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
  width: 4px;
}
::-webkit-scrollbar-track {
  background: #1e1e1e;
}
::-webkit-scrollbar-thumb {
  background: #888;
}

.img_Container {
  width: 500px;
  height: 250px;
  background: #000;
}

.galleryImg {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.box-2 {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(15, 68, 80, 0.9);

  left: 0;
  bottom: -100%;
  z-index: 999;
  transition: ease 0.4s;
}

.box-1:hover .box-2 {
  bottom: 0;
}

.spacing {
  letter-spacing: 3px;
}

.img_container img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.background {
  background: url("../src/assets/img/white-backdrop-with-blue-pattern.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.image-gallery-left-nav,
.image-gallery-right-nav {
  /* padding: 50px 10px; */
  /* top: 50%; */
  transform: translateY(-50%);
}

.event_sponsor {
  white-space: nowrap;
}

.activeNav {
  box-shadow: inset 200px 0 0 0 #052e16;
  color: white;
}
.whileHoverOnNav {
  box-shadow: inset 0 0 0 0 #052e16;
  color: #052e16;
  transition: color 0.6s ease-in-out, box-shadow 0.6s ease-in-out;
}

.whileHoverOnNav:hover {
  box-shadow: inset 200px 0 0 0 #052e16;
  color: white;
}

.largeTofix {
  animation: scaling 1s ease-in-out forwards;
}

.hovScale:hover {
  transform: scale(2);
}
@keyframes scaling {
  0%,
  100% {
    opacity: 1;

    transform: scale(1);
  }
}

.hovScale:hover {
  transform: scale(2);
}
@keyframes scaling {
  0%,
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
  50% {
    opacity: 0.5;
    transform: translateY(-20px);
  }
}

@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.log_slide {
  white-space: nowrap;
  animation: 12s slide infinite linear;
}

@media only screen and (max-width: 1200px) {
  .image_gallery {
    width: 60%;
  }
}

@media only screen and (max-width: 500px) {
  .respoMOB {
    min-height: 300px;
    margin-top: 300px;
  }
}
